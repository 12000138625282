import axios from 'axios';

class OptionsService {
    constructor() {
        this.cancelTokenSource = null;
    }

    createCancelToken() {
        this.cancelTokenSource = axios.CancelToken.source();
    }

    cancelRequest() {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('Request canceled by the user.');
            this.cancelTokenSource = null;
        }
    }

    async get(key, communityId = null) {
        this.createCancelToken();

        try {
            const response = await axios.get('/api/manager/options/get/' + key, {
                params: {
                    community_id: communityId
                },
                cancelToken: this.cancelTokenSource.token,
            });

            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error loading option', error);
            }
        }

        return false;
    }

    async save(key, value, communityId = null) {
        this.createCancelToken();

        try {
            const response = await axios.post('/api/manager/options/save' + (key ? '/' + key : ''), {
                value: value,
                community_id: communityId
            }, {
                cancelToken: this.cancelTokenSource.token,
            });

            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else if (error.response.data.errors) {
                return error.response.data;
            } else  {
                console.error('Error saving option', error);
            }
        }

        return false;
    }

    async delete(key, communityId = null) {
        this.createCancelToken();

        try {
            const response = await axios.delete('/api/manager/options/delete/' + key, {
                params: {
                    community_id: communityId
                },
                cancelToken: this.cancelTokenSource.token,
            });

            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Error deleting option', error);
            }
        }

        return false;
    }

}

export default new OptionsService();