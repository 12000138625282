import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import { getCommunity, authUserPermission } from 'helpers/community';
import { getFromSession, setToSession } from 'helpers/session';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 40px;
			white-space: nowrap;
		}

		&:nth-child(2) {
			width: 100px;
		}

		&:nth-child(4) {
			width: 150px;
		}

		&:nth-child(5) {
			width: 160px;
		}

		&:nth-child(6) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(7) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(8) {
			width: 45px;
			position: relative;

			div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		@media (min-width: 768px) {
			// &:nth-child(1) {
			// 	width: 350px;
			// }
		
			// &:nth-child(2),
			// &:nth-child(3) {
			// 	width: 100px;
			// }

			// &:nth-child(6) {
			// 	width: 40px;
			// }
		}
		
		@media (max-width: 768px) {
			// &:nth-child(2),
			// &:nth-child(4),
			// &:nth-child(5) {
			// 	display: none;
			// }
		}
	}

	.principal-asterisk {
		font-size: 12px;
		color: var(--bs-primary);
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Estimates() {
	const [queryParams] = useSearchParams();

	let tableBodyRef = useRef(null);

	let filters = getFromSession('estimates-list');

	let [estimates, setEstimates] = useState({});
	let [search, setSearch] = useState(filters?.search ?? undefined);
	let [type, setType] = useState(filters?.type ?? undefined);
	let [status, setStatus] = useState(filters?.status ?? undefined);
	let [year, setYear] = useState(filters?.year ?? undefined);
	let [years, setYears] = useState([]);
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'number.full');
	// let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setEstimates({...estimates, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	// const toggleDetails = (rowId) => {
	// 	if ( detailsOpened.includes(rowId) ) {
	// 		detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
	// 		setDetailsOpened([...detailsOpened]);
	// 	} else {
	// 		setDetailsOpened([...detailsOpened, rowId]);
	// 	}
	// }

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getEstimates = () => {
			let params = {
				community_id: getCommunity()?.id,
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				status: status,
				type: type,
				year: year,
			}

			setToSession('estimates-list', params);

			axios.get('/api/manager/estimates/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setEstimates({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getEstimates();

		const getYearsList = () => {
			axios.get('/api/manager/estimates/get-years-list', {
				params: {
					community_id: getCommunity()?.id,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setYears([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getYearsList();
	}, [page, search, status, type, year, sortField, sortDirection]);

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Presupuestos</h1>
					{ authUserPermission('edit') &&
						<NavLink to="/estimates/add" className="btn btn-sm btn-light ms-auto">Nuevo presupuesto</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={filters?.search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setType(e.target.value)} value={type}>
										<option value="">- Tipo -</option>
										<option value="ordinary">Ordinario</option>
										<option value="extraordinary">Extraordinario</option>
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setStatus(e.target.value)} value={status}>
										<option value="">- Estado -</option>
										<option value="1">Vigente / Aprobado</option>
										<option value="0">No vigente / No aprobado</option>
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setYear(e.target.value)} value={year}>
										<option value="">- Año -</option>
										{years.map((el) => {
											return (
												<option key={el} value={el}>{el}</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'number.full'} onClick={() => sortTableClick('number.full')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'type'} onClick={() => sortTableClick('type')}>Tipo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'description'} onClick={() => sortTableClick('description')}>Descripción</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'status'} onClick={() => sortTableClick('status')}>Estado</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'approval_date'} onClick={() => sortTableClick('approval_date')}>Fecha de aprobación</ThSortable>
											<th>Últ. emisión</th>
											<th>Prox. emisión</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody ref={tableBodyRef}>
										{ estimates.data?.length > 0 &&
											estimates.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr 
															// className="has-details" 
															// onClick={() => toggleDetails(el.id)}
															// no pongo details a posta para ahorrar
														>
															<td>{el.number_full}</td>
															<td>{el.type === 'ordinary' ? 'Ordinario' : 'Extraordinario'}</td>
															<td>{el.description ?? '-'}</td>
															<td>
																<div className={'text-white text-center bg-' + (el.status ? 'success' : 'danger')}>
																	{ !el.status ?
																		el.type === 'ordinary' ? 'No vigente' : 'No aprobado'
																		:
																		el.type === 'ordinary' ? 'Vigente' : 'Aprobado'
																	}
																</div>	
															</td>
															<td>{el.approval_date ? moment(el.approval_date).format('DD-MM-YYYY') : '-'}</td>
															<td>
																{ el.lastissuance && 
																	<React.Fragment>
																		<span className="badge bg-light text-secondary">{el.lastissuance.number} de {el.receipts_issues_qty}</span> &nbsp;
																		{ moment(el.lastissuance.date).format('DD-MM-YYYY') }
																	</React.Fragment>
																}
																{ !el.lastissuance && '-' } 
															</td>
															<td>
																{ el.lastissuance && 
																	<React.Fragment>
																		<span className="badge bg-light text-secondary">{el.lastissuance.number + 1}</span> &nbsp;
																		{ moment(el.lastissuance.date).add(el.receipts_first_issuance_perioricity, 'months').format('DD-MM-YYYY') }
																	</React.Fragment>
																}
																{ !el.lastissuance && 
																	(
																		el.receipts_first_issuance_date ? 
																			'[1] ' + moment(el.receipts_first_issuance_date).format('DD-MM-YYYY') 
																		: 
																			'-'
																	)
																} 
															</td>
															<td>
																<div>
																	<NavLink className="btn-table" to={'/estimates/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
																	<NavLink 
																		className="btn-table text-secondary" 
																		to={'/estimates/add?copy_from_id=' + el.id} 
																		onClick={(e) => {
																			if ( !window.confirm('¿Quieres copiar este presupuesto?') ) {
																				e.preventDefault();
																			}
																		}}
																	>
																		<i className="bi bi-copy"></i>
																	</NavLink>
																</div>
															</td>
														</tr>
														{/*{ detailsOpened.includes(el.id) && 	*/}
															{/*<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			
																		</div>
																		<div className="col-md-8">
																			
																		</div>
																	</div>
																</td>
															</tr>*/}
														{/*}*/}
													</React.Fragment>
												);
											})
										}

										{ estimates.data && !estimates.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ estimates.data === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={estimates?.current_page}
									max={estimates?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


