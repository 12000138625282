import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getCommunity } from 'helpers/community';
import { formatNumber, loader } from 'helpers/generic';


const TableMovements = styled.table`
    font-size: 12px;
    
    thead {

        tr {

            th {
                font-weight: 600;
            }
        }
    }

    th, 
    td {
        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(3) {
            width: 100px;
            text-align: right;
        }

        img {
            width: 80px;
        }
    }
`;

const Table = styled.table`
	font-size: 13px;

	thead {
		th {
			font-weight: 500;
		}
	}

    th, 
    td {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            width: 80px;
            white-space: nowrap;

        }

        &:nth-child(5) {
            width: 40px;
        }

        #loader {
            width: 80px;
            display: flex;
            margin: 0 auto;
        }
    }
`;

let axiosCancelToken = null;

export default function PendingReceipts(props) {
    let hideModal = props.hideModal;
    let entity = props.movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0] ?? null; 

    let [loading, setLoading] = useState(false);
    let [pendingReceipts, setPendingReceipts] = useState(null);
    let [financialYear, setFinancialYear] = useState(null);
    let [search, setSearch] = useState(null);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        let getFinancialYear = async () => {
            setLoading(true);
            await axios.get('/api/manager/financial-years/list', {
                params: {
                    community_id: getCommunity()?.id,
                    no_paginate: true
                },
                cancelToken: axiosCancelToken.token
            }).then((response) => {
                setFinancialYear(response.data[0].year ?? null);
            }).catch((error) => {
                if ( axios.isCancel(error) ) return;
                toast.error('Ha ocurrido un error al cargar el ejercicio financiero');
            });
            setLoading(false);
        }
        getFinancialYear();
    }, []);

    useEffect(() => {
        if ( !financialYear ) return;

        let getPendingReceipts = async () => {
            let paid_status = ['unpaid', 'partial'];
            let years = [financialYear, financialYear - 1];

            let pendingReceipts = [];

            setLoading(true);
            for (let status of paid_status) {
                for (let year of years) {
                    try {
                        let response = await axios.get('/api/manager/receipts/list', {
                            params: {
                                community_id: getCommunity()?.id,
                                paid_status: status,
                                date_from: year + '-01-01',
                                date_to: year + '-12-31',
                                no_paginate: true
                            },
                            cancelToken: axiosCancelToken.token
                        });
                        pendingReceipts = [...pendingReceipts, ...response.data];
                    } catch (error) {
                        if (axios.isCancel(error)) return;
                        toast.error('Ha ocurrido un error al cargar los recibos pendientes');
                    }
                }
            }
            setLoading(false);

            setPendingReceipts(pendingReceipts)
        }
        getPendingReceipts();
    }, [financialYear]);

    const saveData = async (receipt) => {
        setLoading(true);

        let movements = [];
        movements.push(entity); // BankMovement
        movements.push({ // Receipt
            class: 'App\\Models\\Receipt',
            concept: 'Recibo ' + receipt.number_full,
            date: receipt.due_date,
            id: receipt.id
        });

        await axios.post('/api/manager/bank-reconciliation/save', {
            type: 'receipt',
            community_id: getCommunity()?.id,
            movements: movements
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Movimientos conciliados');
			hideModal(true, true, [receipt.due_date]);
	  	}).catch((error) => {
			hideModal(true);

			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		})
        setLoading(false);
    }

	return (
		<div className="row">
            <div className="col-md-12 mb-3">
                <label className="sbold">Movimiento bancario a conciliar</label>
                <TableMovements className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Concepto</th>
                            <th>Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{moment(entity.date).format('DD-MM-YYYY')}</td>
                            <td>{entity.concept ?? ''}</td>
                            <td>{formatNumber(entity.amount ?? 0)}</td>
                        </tr>
                    </tbody>
                </TableMovements>
            </div>

            <div className="col-md-12 mt-4">
                <div className="row mb-2">
                    <div className="col-md-8 d-flex align-items-end">
                        <label className="sbold">Recibos pendientes</label>
                    </div>
                    <div className="col-md-4">
                        <input type="search" className="form-control form-control-sm" placeholder='Buscar' value={search ?? ''} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </div>
                <Table className="table table-sm table-bordered mb-0">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Fecha</th>
                            <th>Importe</th>
                            <th>Pagador</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingReceipts && pendingReceipts.filter(item => {
                            let dateFormatted = moment(item.due_date).format('DD-MM-YYYY');
                            let amountFormatted = formatNumber(item.amount);

                            let searchString = item.number_full + ' ' + dateFormatted + ' ' + amountFormatted + ' ' + item.payerable?.name;

                            return !search || searchString.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                        }).map(item => {
                            let dateFormatted = moment(item.due_date).format('DD-MM-YYYY');
                            let amountFormatted = formatNumber(item.amount);
                            
                            return (
                                <tr key={item.id}>
                                    <td>{item.number_full}</td>
                                    <td>{dateFormatted}</td>
                                    <td>{amountFormatted} €</td>
                                    <td>{item.payerable?.name ?? '-'}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary text-white" disabled={loading} onClick={() => saveData(item)}>Conciliar</button>
                                    </td>
                                </tr>
                            );
                        })}
                        { ((!pendingReceipts || !pendingReceipts.length) && !loading) && <tr><td colSpan="100%" className="p-2">No se han añadido recibos pendientes</td></tr> }
                        { (!pendingReceipts && loading) && <tr><td colSpan="100%" className="p-2">{loader}</td></tr> }
                    </tbody>
                </Table>
            </div>
        </div>
	);
}


