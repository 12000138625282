import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { authUserPermission } from 'helpers/community';
import ImportantOwners from 'components/ImportantOwners';
import EmpoweredSelector from 'components/EmpoweredSelector';

const ResponsibleChangerWrapper = styled.div` 
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--bs-gray-300);

    button {
        width: 50px;
        align-self: center;
        margin-top: 10px;
    }
`;

let axiosCancelToken = null;

export default function Details(props) {
	let data = props.data;
	let forceReload = props.forceReload;

    let [editResponsible, setEditResponsible] = useState(false);
    let [responsible, setResponsible] = useState(null);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    const saveResponsible = () => {
        axios.post('/api/manager/communities/'+data.id+'/set-responsible', {
            responsible_id: responsible?.id
        }, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setResponsible(false);
			setEditResponsible(false);

            forceReload();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	
    }

	return (
		<div className="row ms-0 me-0">
            <div className="col-md-4">
                <div><b className="sbold">Nombre</b>: {data.name ?? '-'}</div>
                <div><b className="sbold">Alias</b>: {data.name_short ?? '-'}</div>
                <div><b className="sbold">Código</b>: {data.code ?? '-'}</div>
                <div className="mb-3 sbold">
                    <b className="sbold">Estado</b>:&nbsp;
                    <span className={data.status ? 'text-success' : 'text-danger'}>
                        {data.status ? 'Activa' : 'Inactiva'}
                    </span>
                </div>

                <div><b className="sbold">Razón social</b>: {data.business_name ?? '-'}</div>
                <div><b className="sbold">CIF</b>: {data.vatnumber ?? '-'}</div>
                <div><b className="sbold">Dirección</b>: {data.address ?? '-'}</div>
                <div><b className="sbold">Código postal</b>: {data.postalcode ?? '-'}</div>
                <div><b className="sbold">Ciudad</b>: {data.city ?? '-'}</div>
                <div><b className="sbold">Provincia</b>: {data.province ?? '-'}</div>
                <div><b className="sbold">País</b>: {data.country ?? '-'}</div>
            </div>
            <div className="col-md-8">
                <div><b className="sbold">Fecha creación</b>: {data.date_created ? moment(data.date_created).format('DD-MM-YYYY') : '-'}</div>
                <div className="mb-3"><b className="sbold">Referencia catastral</b>: {data.cadastral_reference ?? '-'}</div>

                <div className="mb-3">
                    <b className="sbold">Responsables</b>:
                    &nbsp;
                    {data.responsibles?.map(el => el.name).join(', ')}
                    {/* &nbsp;
                    { authUserPermission('edit') &&
                        <>
                            <i className="bi bi-pencil cursor-pointer text-primary" onClick={() => setEditResponsible(!editResponsible)}></i>

                            {editResponsible &&
                                <ResponsibleChangerWrapper>
                                    <EmpoweredSelector
                                        load={loadUsers}
                                        onChange={(value) => setResponsible(value)}
                                        timeout={250}
                                        label={responsible?.name ?? ''}
                                        placeholder="Seleccionar responsable"
                                        value={true}
                                        noRemovable={true}
                                    />
                                    <button className="btn btn-sm btn-primary text-white p-0 px-1" onClick={saveResponsible}>Cambiar</button>
                                </ResponsibleChangerWrapper>
                            }
                        </>
                    } */}
                </div>

                { (data.importantowners && data.importantowners.length > 0) && 
                    <div className="row">
                        <div className="col-md-12">
                            <ImportantOwners owners={data.importantowners} />
                        </div>
                    </div>
                }
            </div>
        </div>
	);
}


