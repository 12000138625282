import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import { viewReceiptPdf } from 'helpers/receipts';
import { loader, formatNumber, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';

const TableReceipts = styled.table`
	font-size: 13px;

	tbody {
		border-top: 0 !important;
	}

	td, th {
		&:nth-child(1) {
			width: 20px;
			white-space: nowrap;
		}

		&:nth-child(2) {
			width: 100px;
		}

		&:nth-child(4) {
			width: 300px;
		}

		&:nth-child(5) {
			width: 150px;
			white-space: nowrap;
		}

		&:nth-child(6) {
			width: 80px;
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(7) {
			width: 50px;
			vertical-align: middle;

			a {
				&:not(:last-of-type) {
					margin-right: 8px;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

const Details = (props) => {
    let issuanceId = props.issuanceId;
    let forceReload = props.forceReload;

    let [loading, setLoading] = useState(false);
    let [issuance, setIssuance] = useState(null);
    let [paymentTypeFilter, setPaymentTypeFilter] = useState(null);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        let getIssuance = () => {
            if ( !issuanceId ) return;

            setLoading(true);

            axios.get('/api/manager/receipts-issuances/get/' + issuanceId, {
				params: {
					community_id: getCommunity()?.id,
					with_manual_payment_fields: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setIssuance({...response.data});
                setLoading(false);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
                setLoading(false);
			});
        }
        getIssuance();
    }, [issuanceId]);

    
	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const viewReceiptPdfClick = (e, receipt_id) => {
		e.preventDefault();

		viewReceiptPdf(receipt_id);
	}

	const deleteIssuance = (issuance_id) => {
		const c = window.confirm('¿Seguro que quieres eliminar esta emisión de recibos?');
		if ( !c ) return false;

		axios.post('/api/manager/receipts-issuances/delete/' + issuance_id, {
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token,
		}).then((response) => {
			toast.info('Emisión de recibos eliminada');
            forceReload();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

    let visibleTotal = 0;

    return (
        <>
            { loading && loader }
            { issuance &&
                <>
                    <div className="col-md-4">
                        <div><b className="sbold">Fecha de emisión</b>: {moment(issuance.date).format('DD-MM-YYYY')}</div>
                        <div><b className="sbold">Nº</b>: {issuance.number}</div>
                        <div><b className="sbold">Origen de la emisión</b>: {issuance.entity_name}</div>
                    </div>
                    <div className="col-md-4">
                        <div><b className="sbold">Descripción</b>: {issuance.description}</div>
                        <div><b className="sbold">Nº de recibos</b>: {issuance.receipts_count}</div>
                        <div><b className="sbold">Total recibos</b>: {formatNumber(issuance.total)} €</div>
                    </div>
                    <div className="col-md-4 text-end">
                        { issuance.is_deletable ?
                            <button className="btn btn-sm btn-link text-danger" onClick={() => deleteIssuance(issuance.id)}><small>Eliminar emisión</small></button>
                            :
                            <div className="text-danger" style={{fontSize: '11px', 'lineHeight': '11px'}}>Esta emisión no se puede eliminar por que hay recibos cobrados, remesados o reunificados</div>
                        }
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row mb-2">
                            <div className="col-md-10"></div>
                            <div className="col-md-2">
                                <select className="form-select form-select-sm" value={paymentTypeFilter ?? ''} onChange={(e) => setPaymentTypeFilter(e.target.value)}>
                                    <option value="">Tipo de pago</option>
                                    <option value="manual">Pago manual</option>
                                    <option value="bankremittance">Remesa bancaria</option>
                                </select>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <TableReceipts className="table table-sm table-bordered mb-2">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Vencimiento</th>
                                        <th>Propiedad</th>
                                        <th>Pago</th>
                                        <th>Conceptos</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {issuance.receipts?.filter(el => {
                                        if ( paymentTypeFilter === 'manual' ) {
                                            return el.bankaccount_id === null;
                                        } else if ( paymentTypeFilter === 'bankremittance' ) {
                                            return el.bankaccount_id !== null;
                                        } else {
                                            return true;
                                        }
                                    }).map((rEl, rIdx) => {
                                        visibleTotal += parseFloat(rEl.amount);

                                        return (
                                            <tr key={'r'+rIdx}>
                                                <td>
                                                    {rEl.number_full}
                                                </td>
                                                <td>{moment(rEl.due_date).format('DD-MM-YYYY')}</td>
                                                <td>
                                                    { rEl.property?.id &&
                                                        <>
                                                            {rEl.property.name}&nbsp;
                                                            <NavLink to={'/properties/edit/' + rEl.property.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    { rEl.payerable?.id ?
                                                        <>
                                                            {rEl.payerable?.name}&nbsp;
                                                            { rEl.payerable?.id &&
                                                                <NavLink to={'/'+rEl.payer_type+'s/edit/' + rEl.payerable_id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
                                                            }
                                                        </>
                                                        :
                                                        <div className="text-danger">
                                                            No tiene pagador
                                                        </div>
                                                    }

                                                    <div className="small">
                                                        Pago { rEl.bankaccount_id ? 'por remesa bancaria' : 'al contado' }
                                                    </div>

                                                    { rEl.paid_at &&
                                                        <div className="text-success small">Pagado el { moment(rEl.paid_at).format('DD-MM-YYYY') }</div>
                                                    }	

                                                    { rEl.paid_status === 'partial' && 
                                                        <div className="text-indigo small">Pagado parcialmente</div>
                                                    }

                                                    { rEl.refunded_at &&
                                                        <>
                                                            <div className="text-danger small">
                                                                DEVUELTO <i className="bi bi-dash-circle-fill"></i>

                                                                { rEl.parent ?
                                                                    <span>
                                                                        &nbsp;<i className="bi bi-arrow-right"></i> {rEl.parent.number_full}
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        &nbsp; nuevo recibo no generado
                                                                    </span>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    { (!rEl.refunded_at && rEl.parent) &&
                                                        <>
                                                            <div className="text-primary small">
                                                                REUNIFICADO <i className="bi bi-dash-circle-fill"></i>
                                                                <span>
                                                                    &nbsp;<i className="bi bi-arrow-right"></i> {rEl.parent.number_full}
                                                                </span>
                                                            </div>
                                                        </>
                                                    }

                                                    { rEl.is_reunificated_parent &&
                                                        <>
                                                            <div className="text-primary small">
                                                                GENERADO POR REUNIFICACIÓN DE
                                                                <span>
                                                                    &nbsp;<i className="bi bi-arrow-right"></i> {rEl.refunds?.map(rrEl => {
                                                                        return rrEl.number_full + ' (' + moment(rrEl.due_date).format('DD-MM-YYYY') + ')';
                                                                    }).join(', ')}
                                                                </span>
                                                            </div>
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    {rEl.quotasconcepts?.map((qcEl, qcIdx) => {
                                                        return (
                                                            <div key={'qc'+qcIdx}>{qcEl.name}: {formatNumber(qcEl.pivot?.amount)} €</div>
                                                        );
                                                    })}
                                                </td>
                                                <td>
                                                    {formatNumber(rEl.amount)} €
                                                </td>
                                                <td className="text-center">
                                                    <NavLink to={'/receipts/edit/' + rEl.id} onClick={openPopupInfoWindow}><i className="bi bi-pencil"></i></NavLink>
                                                    <a href="/" onClick={(e) => viewReceiptPdfClick(e, rEl.id)}><i className="bi bi-printer text-secondary"></i></a>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    { issuance.receipts?.length === 0 &&
                                        <tr>
                                            <td colSpan="100%" className="text-start">
                                                No hay recibos
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className="fw-bold">
                                        <td colSpan={5} className="text-end">TOTAL</td>
                                        <td className="text-end">{formatNumber(visibleTotal)} €</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </TableReceipts>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Details;