import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import { authUserPermission } from 'helpers/community';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import ExportModal from 'components/ExportModal';
import Details from './Details';

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(1) {
				width: 350px;
			}
		
			&:nth-child(2),
			&:nth-child(3) {
				width: 100px;
			}

			&:nth-child(6) {
				width: 40px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(5) {
				display: none;
			}
		}
	}

	.principal-asterisk {
		font-size: 12px;
		color: var(--bs-primary);
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Communities() {
	const [queryParams] = useSearchParams();

	let tableBodyRef = useRef(null);

	let [forceReload, setForceReload] = useState(null);
	let [communities, setCommunities] = useState({});
	let [search, setSearch] = useState(undefined);
	let [status, setStatus] = useState(1);
	let [user, setUser] = useState(undefined);
	let [responsible, setResponsible] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('name');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setCommunities({...communities, data: undefined});
		_setPage(page);
	}
	let [showExportModal, setShowExportModal] = useState(false);

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectUser = (value) => {
		setUser({
			id: value?.id,
			name: value?.name
		});
	}

	const selectResponsible = (value) => {
		setResponsible({
			id: value?.id,
			name: value?.name
		});
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getCommunities = async () => {
			await axios.get('/api/manager/communities/list', {
				params: {
					page: page,
					search: search,
					status: status,
					sort: sortField,
					direction: sortDirection,
					responsible_id: responsible?.id,
					user_id: user?.id,
					with_responsibles: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCommunities({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getCommunities();
	}, [forceReload, page, search, status, sortField, sortDirection, user?.id, responsible?.id]);

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Comunidades</h1>
					{ authUserPermission('edit') &&
						<NavLink to="/communities/add" className="btn btn-sm btn-light ms-auto">Nueva comunidad</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setStatus(e.target.value)} value={status}>
										<option value="">- Estado -</option>
										<option value={1}>Activas</option>
										<option value={0}>Inactivas</option>
									</select>
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadUsers}
										onChange={(value) => selectResponsible(value)}
										timeout={250}
										label={responsible?.name ?? ''}
										placeholder="Responsable"
										// showPlaceholderHelper={true}
										value={responsible?.id}
									/>
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadUsers}
										onChange={(value) => selectUser(value)}
										timeout={250}
										label={user?.name ?? ''}
										placeholder="Usuarios"
										value={user?.id}
									/>
								</div>
								<div className="col-md-1 text-end">
									<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'vatnumber'} onClick={() => sortTableClick('vatnumber')}>CIF</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'status'} onClick={() => sortTableClick('status')}>Estado</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'responsibles.name'} onClick={() => sortTableClick('responsibles.name')}>Responsables</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Añadida el</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody ref={tableBodyRef}>
										{ communities.data?.length > 0 &&
											communities.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>
																<span className="badge bg-light text-secondary">{el.name_short}</span> {el.name} 
															</td>
															<td>{el.vatnumber ?? '-'}</td>
															<td>
																<span className={'badge ' + (el.status ? 'bg-success' : 'bg-danger')}>
																	{el.status ? 'Activa' : 'Inactiva'}
																</span>
															</td>
															<td>
																{el.responsibles.map(el => <span className="badge bg-light text-dark me-1">{el.name}</span>)}
																{el.responsibles?.length === 0 && <small>- sin responsables -</small>}
															</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td>
																<NavLink className="btn-table" to={'/communities/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<Details 
																		data={el} 
																		forceReload={() => setForceReload(Math.random())}
																	/>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ communities.data && !communities.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ communities.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={communities?.current_page}
									max={communities?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'communities'}
					fileName={'Comunidades ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						code: 						'Código',
						name: 						'Nombre',
						vatnumber: 					'CIF',
						importantowners: 			'Cargos importantes',
						address: 					'Dirección',
						responsible:				'Responsable'
					}}
					filters={{
						search: 					search,
						status: 					status,
						sort: 						sortField,
						direction: 					sortDirection,
						no_paginate:				true,
						responsible_id:				responsible?.id,
						user_id:					user?.id,
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</LoggedLayout>
	);
}


