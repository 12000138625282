import React, { useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CristalLoader } from 'helpers/generic';

export default function Table2Pdf(props) {
    const tableRef = props.tableRef;
    const customButton = props.button;
    const pdfName = props.pdfName;

    const [loading, setLoading] = useState(false);

    const generatePDF = async () => {
        const input = tableRef.current;

        setLoading(true);
        await html2canvas(input, { scale: 1.3 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.8); // Compress the image
            const pdf = new jsPDF();
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            let imgHeight = (canvas.height * pdfWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight;
            }

            pdf.save((pdfName ?? 'captura-pantalla') + '.pdf');
        });
        setLoading(false);
    };

    return (
        <>
            {loading && <CristalLoader />}
            {!customButton &&
                <button 
                    className="btn btn-link" 
                    onClick={generatePDF}
                >
                    <i className="bi bi-file-earmark-pdf"></i>
                </button>
            }
            {customButton && React.cloneElement(customButton, { onClick: generatePDF })}
        </>
    );
}