import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import OptionsService from "services/options";
import { getCommunity } from "helpers/community";
import { loader } from "helpers/generic";
import ActionsContextMenu from 'components/ActionsContextMenu';

const InstructionsStyled = styled.div`
    position: relative;
    margin-bottom: 10px;

    #loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        z-index: 2;
    }

    .button-container {
        text-align: right;

        button {
            i {
                font-size: 20px;
            }
        }
    }

    .text-container {
        display: ${props => props.opened === true ? 'block' : 'none'};
        position: relative;

        textarea {
            resize: none;
            background: white;
            border: 1px solid var(--bs-primary);
            overflow: hidden;

            &:read-only {
                /* background: #f8f9fa; */
            }
        }

        button {
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: white;
        }
    }
`;

export default function Instructions() {
    const instructionsTextRef = useRef(null);
    const textareaRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);

    const [editable, _setEditable] = useState(false);
    const setEditable = (status) => {
        _setEditable(status);
        if (status) {
            setTimeout(() => { // Timeout fix to prevent do weird things
                textareaRef.current.focus();
            }, 0);
        }
    }
    
    const [instructions, _setInstructions] = useState(null);
    const setInstructions = (value) => {
        _setInstructions(value);
        setBtnSaveVisible(value !== instructionsTextRef.current);
    }

    const [btnSaveVisible, setBtnSaveVisible] = useState(false);

    useEffect(() => {
        let getData = async () => {
            let data = await OptionsService.get('bank_reconciliation_instructions', getCommunity()?.id);
            let text = data ?? null;
            instructionsTextRef.current = text;
            setInstructions(text);
            if (text) setOpened(true);
        }
        getData();
    }, []);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [instructions]);

    const toggleOpened = () => {
        let newStatus = !opened;

        setOpened(newStatus);
        if (newStatus && instructions.length === 0) setEditable(true);
    }

    const saveInstructions = async () => {
        setLoading(true);
        let result = await OptionsService.save('bank_reconciliation_instructions', instructions, getCommunity()?.id);
        setLoading(false);

        if (result.status) {
            instructionsTextRef.current = instructions;
            setBtnSaveVisible(false);
            setEditable(false);

            if (instructions.length === 0) {
                setOpened(false);
            }
        }
    }

    return (
        <>
            <InstructionsStyled opened={opened}>
                {loading && loader}

                <div className="button-container">
                    <button className="btn btn-lg btn-link py-0" onClick={() => toggleOpened()}><i className="bi bi-info-circle"></i></button>
                </div>

                <div className="text-container">
                    <textarea ref={textareaRef} className={`form-control`} value={instructions ?? ''} onChange={(e) => setInstructions(e.target.value)} readOnly={!editable}></textarea>
                    {(btnSaveVisible && !loading) &&
                        <button className="btn btn-sm btn-primary" onClick={saveInstructions}>Guardar cámbios</button>                
                    }
                </div>
            </InstructionsStyled>

            <ActionsContextMenu 
                actionsDropdownRef={textareaRef} 
                className="dropdown-menu"
                activationElement={textareaRef.current}
                allowedElements={[textareaRef.current]}
                showPasteButton={true}
            >
                {!editable &&
                    <li><button className="dropdown-item" onClick={() => setEditable(true)}>Editar</button></li>            
                }     
                <li><button className="dropdown-item" onClick={saveInstructions} disabled={loading}>Guardar</button></li>         
            </ActionsContextMenu>
        </>
    );
}